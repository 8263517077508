import wx from 'weixin-js-sdk'
import { getSignature } from '@/api/wx'

export function isWeixin() {
  var ua = navigator.userAgent.toLowerCase();
  var isWeixin = ua.indexOf('micromessenger') != -1;
  if (isWeixin) {
    return true;
  } else {
    return false
  }
}

export function initConfig(url) {
  return new Promise((reslove, reject) => {
    getSignature({
      webUrl: url
    }).then(res => {
      wx.config({
        // debug: true,
        ...res.data,
        jsApiList: ['updateAppMessageShareData']
      })
      wx.ready(function (res) {
        reslove()
      });
    })
  })
}

export function wxShare(options, url = window.location.href) {
  if (!isWeixin()) {
    return
  }
  initConfig(url)
    .then(res => {
      wx.updateAppMessageShareData({
        title: options.title, // 分享标题
        desc: options.desc, // 分享描述
        link: options.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: options.imgUrl, // 分享图标
        success: function () {
          // 设置成功
          console.log('设置成功')
        }
      })
    })
}