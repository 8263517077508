<template>
  <div class="curriculum-free">
    <downBtn
      v-if="!type"
      :topShow="true"
      :isWx="isWx"
      @down="download"
      @wechatShow="wechatShow"
    ></downBtn>
    <video
      :class="!type ? 'app-video' : 'open-class'"
      :src="courseData.videoUrl"
      controls
      poster=""
      preload
      ref="refVideo"
      id="refVideo"
      controlslist="nodownload"
      x-webkit-airplay="allow"
      webkit-playsinline="true"
      x5-playsinline="true"
      x5-video-player-type="h5"
      x5-video-player-fullscreen="false"
      playsinline="true"
      preload="auto"
      x5-video-orientation="h5"
      autoplay
      @play="play"
    >
      浏览器不支持播发该视频
    </video>

    <div class="curriculum-details">
      <div class="left">
        <div class="info">
          <div class="name">入门必学</div>
          <div>{{ courseData.chapterName }}</div>
        </div>
        <div class="number">
          学习次数
          <span>{{ studyCount }}</span>
        </div>
      </div>
      <div class="right" v-if="!type">
        <img @click="maskShow = true" src="@/assets/20g.gif" alt="" />
      </div>
    </div>
    <div class="teacher">
      <div
        class="teacher-info"
        v-for="(el, i) in courseData.audioList"
        @click="handlePlayAudio(i, el.play)"
      >
        <div class="teacher-tape-reading">
          <img src="@/assets/onionIcon.svg" alt="" />
          <div class="audio-name">
            <div>{{ el.sourceName }}</div>
            <div class="audio-time">00:00/{{ el.sourceTime }}</div>
          </div>
        </div>
        <img v-if="!el.play" src="@/assets/audio.svg" alt="" />
        <img v-else src="@/assets/play.gif" alt="" />
        <audio
          id="myaudio"
          class="myaudio"
          @ended="audioEnded($event, i)"
          :src="el.sourceUrl"
        ></audio>
      </div>
    </div>
    <div class="footer">
      <img
        v-for="(img, j) in courseData.imgList"
        :index="img.sourceUrl"
        :src="img.sourceUrl"
        alt=""
      />
    </div>
    <div class="mask-box pos-fiexd" v-show="maskShow">
      <!-- <div class="mask"></div> -->
      <div class="mask-content p-a-c bg-fff">
        <div class="close text-right">
          <img
            src="@/assets/close.png"
            alt=""
            style="width: 14px; height: 14px"
            @click="changeMask"
          />
        </div>
        <div class="content text-center">
          <div class="text">下载洋葱韩语app</div>
          <div class="btn" @click="download">
            <span>去下载</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import downBtn from "@/components/downBtn.vue";
import mixin from "@/utils/mixin";
import { getCourseInfo, chapterDetail } from "@/api/curriculum";
import { wxShare } from "@/utils/wx";

export default {
  mixins: [mixin],
  name: "curriculumFree",
  components: {
    downBtn,
  },
  props: {},
  data() {
    return {
      maskShow: false,
      courseData: {},
      studyCount: "",
      type: this.$route.query.type,
    };
  },
  created() {
    this.getCourseInfo();
  },
  methods: {
    getCourseInfo() {
      let params = {
        courseId: this.$route.query.id || "42",
      };
      getCourseInfo(params).then((res) => {
        if (res.code !== "SUCCESS") return;
        this.studyCount = res.data.studyCount;
        this.chapterDetail(
          res.data.chapterVOList[0].courseId,
          res.data.chapterVOList[0].id
        );
      });
    },
    // 获取章节详情
    chapterDetail(courseId, chapterId) {
      // alert(1);
      chapterDetail({
        chapterId,
        courseId,
      }).then(async (res) => {
        if (res.code !== "SUCCESS") return this.$message.error(res.message);
        var audioElement = new Audio(res.data.videoUrl);
        audioElement.addEventListener("loadedmetadata", () => {
          res.data.videoTime = audioElement.duration;
        });
        let audioList = res.data.sourceList.filter(
          (item) => item.sourceType === 1
        );
        audioList.forEach((item) => {
          item.play = false;
        });
        res.data.audioList = audioList;
        res.data.imgList = res.data.sourceList.filter(
          (item) => item.sourceType === 0
        );
        this.isPalyComplete = false;
        this.activeChapter = res.data.id;
        this.courseData = res.data;
        console.log(
          res.data.imgList[0].sourceUrl,
          "res.data.imgList[0].sourceUrl"
        );
        if (this.type) {
          wxShare(
            {
              title: "0基础极速入门", // 分享标题
              desc: "点击链接进入课堂上课啦", // 分享描述
              link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              // imgUrl: res.data.imgList[0].sourceUrl, // 分享图
              imgUrl:
                "https://jixedu.oss-cn-hangzhou.aliyuncs.com/data/live/2022/05/31/1653976402726165e04c7-e013-4176-95a5-90406abc9209.png",
            },
            window.location.href
          );
        }
        console.log(this.courseData, "this.courseData");
      });
    },
    changeMask() {
      this.maskShow = !this.maskShow;
    },
    // 章节详情 播放音频
    handlePlayAudio(index, flag) {
      if (!this.type) return (this.maskShow = true);
      let audio = document.querySelectorAll(".myaudio");
      let video = document.getElementById("refVideo");
      if (!flag) {
        for (let i = 0; i < audio.length; i++) {
          if (index === i) {
            audio[index].play();
            this.$set(this.courseData.audioList[i], "play", true);
            video.pause();
          } else {
            audio[i].pause();
            audio[i].load();
            this.$set(this.courseData.audioList[i], "play", false);
          }
        }
      } else {
        audio[index].pause();
        audio[index].load();
        this.$set(this.courseData.audioList[index], "play", false);
      }
    },
    play() {
      let audio = document.querySelectorAll(".myaudio");
      for (let i = 0; i < audio.length; i++) {
        audio[i].pause();
        audio[i].load();
        this.$set(this.courseData.audioList[i], "play", false);
      }
    },
    audioEnded(el, index) {
      this.$set(this.courseData.audioList[index], "play", false);
    },
  },
};
</script>

<style scoped lang="scss">
.curriculum-free {
  width: 100vw;
}
.app-video {
  margin: 37px 0 0;
  height: 233px;
  width: 100%;
  object-fit: fill;
}
.open-class {
  height: 233px;
  width: 100%;
  object-fit: fill;
}
.curriculum-details {
  display: flex;
  position: relative;
  .left {
    padding: 16px;
    box-sizing: border-box;
    .info {
      display: flex;
      align-items: center;
      color: #333;
      font-size: 18px;
      font-weight: 550;
    }
    .name {
      margin-right: 12px;
      width: 59px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      background: #ffcd82;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
      font-weight: 500;
    }
    .number {
      margin: 13px 0 0;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #666666;
      span {
        margin: 0 0 0 8px;
        color: #333333;
      }
    }
  }
  .right {
    position: fixed;
    right: 1px;
    top: 300px;
    z-index: 9;
    img {
      display: block;
      width: 117px;
    }
  }
}
.teacher {
  padding: 16px;
  box-sizing: border-box;
  // height: 106px;
  background: #f4f8ff;
  .teacher-info {
    margin: 6px 0 0;
    padding: 0 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 66px;
    border-radius: 6px;
    border: 1px solid #87b8ff;
    img {
      display: block;
      width: 40px;
      height: 40px;
    }
    .teacher-tape-reading {
      display: flex;
      img {
        margin-right: 16px;
        display: block;
        width: 44px;
        height: 44px;
      }
      .audio-name {
        display: flex;
        // flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-around;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
      }
      .audio-time {
        font-size: 14px;
        color: #333333;
      }
    }
  }
}
.footer {
  img {
    display: block;
    width: 100vw;
  }
}
</style>
