import api from "@/utils/request";
const loginUrl = process.env.VUE_APP_LOGIN_API_URL

// 获取签名
export function getSignature(params) {
  return api({
    method: "get",
    url: loginUrl + "/weChat/official/getShareOfficialSignature",
    params
  });
}
